import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { At, Phone } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  languageCode: string
  phone?: string
  siteName?: string
}

export const ContactDetails = memo(function ContactDetails({
  address,
  email,
  languageCode,
  phone,
  siteName,
}: Props) {
  return (
    <Container>
      <Head>
        <FadeInUp>
          <SiteName
            className="text-mark"
            dangerouslySetInnerHTML={{
              __html: `The <b>RomeHello</b>`,
            }}
          />

          {address ? (
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          ) : null}
        </FadeInUp>
      </Head>

      <Wrapper dial={2} row wrap>
        <Item>
          <FadeInUp>
            <Phone />

            <Label>{useVocabularyData('call-us', languageCode)}</Label>

            {phone ? (
              <Anchor
                href={`tel:${phone}`}
                onClick={() => {
                  if (isMobile) {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Phone Number',
                      })
                  }
                }}
              >
                {phone}
              </Anchor>
            ) : null}
          </FadeInUp>
        </Item>

        {email ? (
          <Item>
            <FadeInUp>
              <At />

              <Label>{useVocabularyData('write-us', languageCode)}</Label>

              <Anchor
                href={`mailto:${email}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {email}
              </Anchor>
            </FadeInUp>
          </Item>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  text-align: center;
`

const Head = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 10.625rem 1.875rem;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }
`

const SiteName = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`

const Address = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
  }
`

const Wrapper = styled(FlexBox)`
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Item = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 5.375rem 1.875rem;
  &:nth-of-type(2) {
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    color: ${({ theme }) => theme.colors.variants.primaryDark};

    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark};
    }

    a {
      color: ${({ theme }) => theme.colors.variants.primaryDark};
    }
  }

  svg {
    width: auto;
    height: 3rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    margin-bottom: 1.5625rem;
  }

  @media (max-width: 1199px) {
    padding: 2.5rem 1.875rem;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  &:hover {
    text-decoration: underline;
  }
`
